import { BaseModel } from './base.model';

export class FilterItem extends BaseModel {
    public id: any;
    public attr_id: any;
    public ord: number;
    public label: string;
    public selected: boolean;
    public isChild: boolean;
    public isVisible = true;
    // derived properties
    public children: FilterItem[];

    constructor(data) {
        super(data, {
            num: [ 'ord'],
            bool: ['selected']
        });

        if (!this.children) {
           this.children = [];
        } else {
            this.children = this.children.map( item => new FilterItem(item));
        }

        if (!this.ord) {
            this.ord = 0;
        }
    }

    public clearSelection() {
        this.selected = false;
        this.children.forEach( child => child.clearSelection());
    }
}
