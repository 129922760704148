import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import {catchError, debounceTime, filter, switchMap, takeWhile, tap} from 'rxjs/operators';

import { EntityService } from '../../../services/entity.service';
import { ProductService } from '../../../services/product.service';
import { CostCenter } from '../../../models/cost-center.model';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../../../services/order.service';
import { Entity } from '../../../models/entity.model';
import { Order } from '../../../models/order.model';
import { CreditCard } from '../../../models/credit-card.model';
import { PaymentMethod } from '../../../models/payment-method.model';
import {PaymentService} from '../../../services/payments.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth.service';
import {CUSTOM_TYPE, ECOMMERCE_TYPE} from '../../../constants/order-types';
import {IDecisionPointData} from '../../../interfaces/decision-point.data';
import {INTERNAL_ORDER_LENGTH, WBS_LENGTH} from '../../../constants/globals';
import {isEmptyArray} from '../../../shared/utils';
import {ConfigService} from '../../../services/config.service';
import {Address} from '../../../models/address.model';
import {flatternSortedAddressesByWslr} from '../../../shared/helpers';
import {AddressService} from '../../../services/address.service';

enum SAP_TYPE {
    STANDARD,
    DECISION_POINT,
    WHOLESALER
}

// submit sap number event
export interface SapCostDataEvent {
    costCenter: CostCenter;
    decisionPointId?: number;
}

@Component({
    selector: 'app-sap-modal',
    templateUrl: './sap-modal.component.html',
    styleUrls: ['./sap-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SapModalComponent implements OnInit, OnDestroy {

    alive = true;

    SapType = SAP_TYPE;
    isSapType: SAP_TYPE = SAP_TYPE.STANDARD;

    canCreateWLSROrder = false;

    costCenter: CostCenter;
    creditCard: CreditCard;

    // decision point search
    dpSearchResults: IDecisionPointData[] = [];
    dpSearchLoading = false;
    dpSearchSubject: Subject<string> = new Subject<string>();
    dpSelection: IDecisionPointData = {id: '0', wslr_count: 0, store_count: 0};
    dpSelectionId: string;

    // wholesaler search
    wholesalerSearchResults: Entity[] = [];
    wholesalerSearchLoading = false;
    wholesalerSearchSubject: Subject<string> = new Subject<string>();
    wholesalerSelection: any = {id: 0};
    wholesalerSelectionId: number;



    closeWithWarning = false;
    canChangeOrderType = true;


    @Input() orderType: string;

    originalOrderName = '';
    orderName = '';
    dpOrderName = '';
    wslrOrderName = '';
    wslrTaxExempt = false; // tax exempt for WSLR orders is true by default
    isWslrTaxExemptAllowed = false;

    poNumber: string;
    attention: string;
    originalAttention: string;
    taxExempt = false;

    order: Order;

    inProgress = false;
    saveCard = false;

    headerLabel = '';

    private attentionFieldClicked = false;


    companyBillingEntity: string;
    vendorNumber: string;
    internalOrder: string;
    wbs: string;

    presetAddresses: Address[] = [];

    isNewOrder = true; // flag to indicate if order is new or existing
    isWBSSelected = false;
    isInternalOrderSelected = false;

    constructor(
        protected toastr: ToastrService,
        private entityService: EntityService,
        private productService: ProductService,
        private paymentService: PaymentService,
        private orderService: OrderService,
        private activeModal: NgbActiveModal,
        private authService: AuthService,
        private config: ConfigService,
        private addressService: AddressService) {
    }


    ngOnInit() {
        this.initDPSearch();
        this.initWholesalerSearch();
        this.showSapModal(this.order);
    }

    ngOnDestroy() {
        this.alive = false;
    }


    // public methods

    // show modal dialog
    public showSapModal(order: Order) {

        if (this.order) {

            this.orderName = this.order.attr_cart_name;
            this.originalOrderName = this.orderName;
            this.poNumber = this.order.attr_po_num || '';
            this.costCenter = this.order.costCenter;
            this.creditCard = this.order.creditCard;
            this.attention = this.order.isAttentionOverridden ? '' : (this.order.attr_attention || '');
            this.originalAttention = this.attention;

            this.taxExempt = this.order.taxExempt;

            this.wslrOrderName = this.order.cartName;
            this.dpOrderName = this.orderName;

            // check if order is Decision Point
            if (this.order.isDecisionPoint) {
                this.isSapType = this.SapType.DECISION_POINT;

                if (this.order.decisionPointData) {
                    this.dpSelection = this.order.decisionPointData;
                }
            } else if (this.order.isWSLR) {
                this.isSapType = this.SapType.WHOLESALER;

                // check if tax exempt is allowed
                this.wslrTaxExempt = this.order.taxExempt;
                if (this.order.entity) {
                    this.isWslrTaxExemptAllowed = this.order.entity.isTaxExemptAllowed;
                }
            }

            this.companyBillingEntity = this.order?.costCenter?.company_billing_entity ||  this.order.attr_company_billing_entity || '';
            this.vendorNumber = this.order?.costCenter?.vendor_number   || this.order.attr_vendor_number || '';
            this.internalOrder =  !!this.order.attr_internal_order ? this.order.attr_internal_order : '';
            this.wbs = !!this.order.attr_wbs ? this.order.attr_wbs : '';

            if (!!this.internalOrder) {
                this.isInternalOrderSelected = true;
                this.isWBSSelected = false;
            }

            if (!!this.wbs) {
                this.isWBSSelected = true;
                this.isInternalOrderSelected = false;
            }

        }
        this.presetAddresses = this.order.presetAddresses;

        this.isNewOrder = !this.companyBillingEntity; // this is an only way to check if order is new or existing

    }

    get canSwitchOrderType(): boolean {
        if (!this.canChangeOrderType) {
            return false;
        }

        if (!this.canAddDecisionPointOrder && !this.canCreateWLSROrder) {
            return false;
        }

        if (this.order.type === CUSTOM_TYPE) {
            return false; // autoship order type cannot be changed
        }

        if (this.order.item_count > 0 || this.order.isWSLR || this.order.isDecisionPoint) {
            return false;
        }

        if (!this.authService.isEmployee) {
            return false;
        }

        return true;

    }





    // hide and reset modal dialog
    hideSapModal(order: Order) {
        this.orderName = '';
        this.wholesalerSelection = {id: 0};
        this.wholesalerSelectionId = this.wholesalerSelection.id;
        this.dpSelection = {id: '0', store_count: 0, wslr_count: 0};
        this.dpSelectionId = this.dpSelection.id;
        this.isSapType = SAP_TYPE.STANDARD;

        if (this.closeWithWarning) {
            this.toastr.error('Your item was not saved. You must complete the form before we can add items to this order.');
            this.closeWithWarning = false;
        }

        this.activeModal.close(order);
    }

    get canCloseDialog(): boolean {

        if (this.isNewOrder) {
            return false;
        }
        return this.isWBSOrIODefined;
        // const isWBSValid = this.wbs && this.wbs.length > 0;
        // const isInternalOrderValid = this.internalOrder && this.internalOrder.length > 0;
        //
        // return isWBSValid || isInternalOrderValid;
    }

    get isWBSOrIODefined(): boolean {
        const isValid = (val: string): boolean => {
            const newValue  =  !!val ? val.trim() : '';
            return !!newValue  && newValue.length > 0 && newValue !== '';
        }
        return isValid(this.wbs) || isValid(this.internalOrder);
    }


    get canApply(): boolean {
        if (this.verifyingSAP || this.updatingPaymentMethod) {
            return false;
        }

        if (this.order.error) {
            return false;
        }


        if (!this.isWBSOrIODefined) {
            return false;
        }

        if (this.order.isCustom) {
            // cost center should be defined for custom order type
            return this.isCostCenterDefined;
        }

        if (this.authService.canShowSAP) {
            if (this.order.isByingWindow) {
                if (this.isSapType === SAP_TYPE.STANDARD) {
                    return this.isCostCenterDefined;
                } else if (this.isSapType === SAP_TYPE.DECISION_POINT) {
                    return this.isCostCenterDefined && this.isDPSelected;
                } else {
                    return true;
                }

            }
        }

        if (this.isSapType === SAP_TYPE.STANDARD) {
            return this.isPaymentDefined;
        } else if (this.isSapType === SAP_TYPE.DECISION_POINT) {
            return this.isPaymentDefined && this.isDPSelected;
        } else {
            return true;
        }
    }


    private get isDPSelected(): boolean {
        return !!this.order.attr_decision_point_id || (this.dpSelection && this.dpSelection.id !== '0');
    }

    private get isPaymentDefined(): boolean {
        return !!this.creditCard || this.isCostCenterDefined;
    }

    private get isCostCenterDefined(): boolean {
        return this.costCenter && this.costCenter.verified;
    }


    private get isOrderNumberDefined(): boolean {
        return !!this.wbs || !!this.internalOrder;
    }

    // apply sap
    async onApplyPayment() {

        if (this.isWBSOrIODefined === false) {
            this.toastr.warning('Either IO or WBS must be provided.\n' +
              'Please input a value.');
            return;
        }


        this.closeWithWarning = false;
        this.inProgress = true;

        if (this.creditCard) {
            if (this.creditCard.isValid) {
                if (this.saveCard) {
                    await  this.paymentService.saveCreditCardForFuture(this.order.id, this.creditCard.label).toPromise();
                }
            } else {
                this.inProgress = false;
                return this.toastr.warning('Cannot link the order with unverified card.')
            }
        }

        // validate internal_order and wbs length
        if (this.internalOrder && this.internalOrder !== '0') {
            if (this.internalOrder.length > INTERNAL_ORDER_LENGTH) {
                this.inProgress = false;
                return this.toastr.warning(`Internal Order should not exceed ${INTERNAL_ORDER_LENGTH} symbols.`);
            }
        }


        // if (this.wbs && this.wbs !== '0') {
        //     if (this.wbs.length !== WBS_LENGTH) {
        //         this.inProgress = false;
        //         return this.toastr.warning(`WBS should be exactly ${WBS_LENGTH} symbols.`);
        //     }
        // }


        const orderAttributes = {
            cart_name: this.orderName
        };

        if (this.isSapType === SAP_TYPE.DECISION_POINT &&  this.dpSelection && this.dpSelection.id !== '0') {
            orderAttributes['decision_point_id'] = this.dpSelection.id;
            this.order.attr_decision_point_id = this.dpSelection.id;
            orderAttributes['cart_name'] = this.dpOrderName;
            this.order.attr_cart_name = this.dpOrderName;
            this.order.decisionPointData = {
                id: this.dpSelection.id,
                label: this.dpSelection.label,
                wslr_count: this.dpSelection.wslr_count,
                store_count: this.dpSelection.store_count
            }

            orderAttributes['notes'] = this.dpOrderName;
            if (!this.poNumber) {
                this.poNumber = this.order.attr_cart_name;
            }
        }
        if (this.isTaxExemptAllowed) {
            orderAttributes['tax_exempt'] = this.taxExempt.toString();
        }

        this.order.attr_po_num = this.poNumber;
        orderAttributes['po_num'] = this.poNumber;


        orderAttributes['business_unit'] = this.costCenter.business_unit;
        orderAttributes['company_billing_entity'] = this.companyBillingEntity;
        orderAttributes['vendor_number'] = this.vendorNumber;
        orderAttributes['internal_order'] = !!this.internalOrder ?  this.internalOrder.trim() :  '';
        orderAttributes['wbs'] = !!this.wbs  ? this.wbs.trim() : '';

        if (isEmptyArray(this.presetAddresses)) {
            orderAttributes['preset_addresses'] = [];
        } else {
            orderAttributes['preset_addresses'] = this.presetAddresses.map( address => {
                if (!address.entity_id) {
                    return {id: address.id};
                }
                return {id: address.id, entity_id: address.entity_id};
            });
        }

        if (this.isAttentionChanged) {
            this.order.attr_attention = this.attention || '';
            orderAttributes['attention'] = this.order.attr_attention;
        }

        const retOrder: Order = await this.orderService.setOrderAttributes(this.order, orderAttributes).toPromise();
        this.inProgress = false;
        if (retOrder && !retOrder.error) {
            this.order = retOrder;

            if (this.isNewOrder) {
                const message = 'New ' + (this.order.type === ECOMMERCE_TYPE ? 'cart' : 'order') + ' created.';
                this.toastr.success(message);
            } else {
                this.toastr.success('Cart details updated.');
            }

            this.hideSapModal(retOrder);
        }
    }

    // DP select handler
    selectDP(selection) {
        this.dpSelection = selection;
        this.dpSelectionId = this.dpSelection.id;
        this.dpOrderName = `${this.dpSelection.label} ${this.orderService.getDefaultOrderName(this.order.type)}`;
    }

    // Wholesaler select handler
    selectWholesaler(selection) {
        this.wholesalerSelection = selection;
        this.wholesalerSelectionId = this.wholesalerSelection.id;
        this.wslrOrderName = `WSLR #${selection.external_id} ${this.orderService.getDefaultOrderName(this.order.type)}`;
        // this.wslrAttention = selection;
    }


    // create new wholesaler
    createWholesalerOrder() {
        if (this.canSwitchOrderType) {
            if (this.wholesalerSelection.id > 0) {

                // update order entity id  and cart name
                this.order.attr_cart_name = this.wslrOrderName;
                this.order.entity_id = this.wholesalerSelection.id;
                this.order.isWSLR = true;

                const orderAttrs = {
                    cart_name: this.wslrOrderName,
                };

                if (this.isAttentionChanged) {
                    this.order.attr_attention = this.attention  || '';
                    orderAttrs['attention'] = this.order.attr_attention;
                }

                this.order.taxExempt = this.wslrTaxExempt;
                orderAttrs['tax_exempt'] = this.order.taxExempt.toString();

                this.inProgress = true;

                this.paymentService.updatePaymentMethod(this.order, null).pipe(
                 switchMap( retOrder => {
                     if (!retOrder) {
                         return of(null);
                     }
                     return this.orderService.updateEntity(retOrder, this.wholesalerSelection.id)
                 }),
                switchMap(  retOrder => {
                    if (!retOrder) {
                        return of(null);
                    }
                    return this.orderService.setOrderAttributes(retOrder, orderAttrs);

                })).subscribe((order: Order) => {
                    this.inProgress = false;
                    if (order) {
                        this.closeWithWarning = false;
                        this.hideSapModal(order);
                    }
                });
            }
        } else {
            // only allowed to change  order name
            const orderAttrs = {
                cart_name: this.wslrOrderName,
            };

            if (this.isAttentionChanged) {
                this.order.attr_attention = this.attention  || '';
                orderAttrs['attention'] = this.order.attr_attention;
            }

            this.order.taxExempt = this.wslrTaxExempt;
            orderAttrs['tax_exempt'] = this.wslrTaxExempt.toString();

            this.order.attr_cart_name = this.wslrOrderName;
            this.order.isWSLR = true;
            this.inProgress = true;

            this.paymentService.updatePaymentMethod(this.order, null).pipe(
                switchMap(retOrder => {
                    if (!retOrder) {
                        return of(null);
                    }
                    return this.orderService.setOrderAttributes(retOrder, orderAttrs);
                })
            ).subscribe((retOrder) => {
                this.inProgress = false;
                if (retOrder) {
                    this.hideSapModal(retOrder);
                }

            });
        }
    }


    close() {
        this.hideSapModal(null);
    }

    // getters
    // check if wholesaler cna be created
    get canCreateWSLROrder(): boolean {
        if (this.canSwitchOrderType === false) {
            return true;
        } else if (this.wholesalerSelection.id > 0) {
            return true;
        }
        return false;
    }

    // private methods

    // init DP dropdown
    private initDPSearch() {
        this.dpSearchSubject.pipe(
            takeWhile(() => this.alive),
            debounceTime(400),
            filter( term => !!term),
            tap(() => this.dpSearchLoading = true),
            switchMap(term => this.findDecisionPoints(term)),
            catchError(() => of([])), // empty list on error
            tap(() => {
                this.dpSearchLoading = false;
            })
        ).subscribe( result => {
            this.dpSearchResults = result || [];
        });
    }


    // init Wholesaler dropdown
    private initWholesalerSearch() {
        this.wholesalerSearchSubject.pipe(
            takeWhile(() => this.alive),
            debounceTime(400),
            filter( term => !!term),
            tap(() => this.wholesalerSearchLoading = true),
            switchMap((term: string) => this.findWholeSalers(term)),
            catchError(() => of([])), // empty list on error
            tap(() => {
                this.wholesalerSearchLoading = false;
            })
        ).subscribe( result => {
            this.wholesalerSearchResults =  result || [];
        })

    }



    // search for wholesaler
    private findWholeSalers(searchingName: string): Observable<Entity[]> {
        return this.entityService.search(searchingName);
    }


    // search for DP
    private findDecisionPoints(search: string): Observable<IDecisionPointData[]> {
        if (!search) {
            return of([]);
        }
        return this.productService.searchDecisionPoints(search);
    }


    private setOrderName(val: string) {
        if (!this.originalOrderName) {
            this.orderName = val;
        }
    }

    onSavedCard(event) {
        this.saveCard = event;
    }

    get canShowTaxExempt(): boolean {
        return this.authService.isWholeSaler || this.order.isWSLR;
    }

    public get isTaxExemptAllowed(): boolean {
        return this.authService.user.isTaxExemptAllowed;
    }

    public get isWSLRTaxExemptAllowed(): boolean {
        return this.wholesalerSelection?.allow_tax_exempt === true;
    }

    get canShowWSLRTaxExempt(): boolean {
        return this.isWSLRTaxExemptAllowed;
    }

    public get isAttentionChanged(): boolean {
        if (!this.order) {
            return false;
        }

        return this.attentionFieldClicked || (this.originalAttention !== this.attention);
    }

    public checkForAttentionChanges() {
       this.attentionFieldClicked = true;
    }

    onPaymentChanged(paymentMethod: PaymentMethod) {
        this.paymentService.updatePaymentMethod(this.order, paymentMethod).subscribe( retOrder => {
            if (retOrder) {
                this.order = retOrder;

                if (this.order.costCenter) {
                    this.costCenter = this.order.costCenter;
                    this.creditCard = null;
                    this.companyBillingEntity = retOrder.costCenter?.company_billing_entity || '';
                    this.vendorNumber = retOrder.costCenter?.vendor_number || '';
                    this.setOrderName(this.costCenter.label);
                } else if (this.order.creditCard) {
                    this.creditCard = this.order.creditCard;
                    this.costCenter = null;
                    this.setOrderName(this.creditCard.label);
                } else {
                    this.costCenter = null;
                    this.creditCard = null;
                }
            }

        });

    }
    get verifyingSAP(): boolean {
        return this.paymentService.verifyingSAP;
    }

    get updatingPaymentMethod(): boolean {
        return this.paymentService.updatingPaymentMethod;
    }

    get canAddDecisionPointOrder(): boolean {
        return this.authService.canEditDecisionPoints;
    }


    internalOrderChanged() {
        this.wbs = '';
    }

    wbsChanged() {
        this.internalOrder = '';
    }


    get canChangeWBS(): boolean {
        // can change WBS or Internal order only if order is empty
        if (!isEmptyArray(this.order.items)) {
            return false;
        }


        return this.order.item_count === 0;
    }



    get isPresetAddressesAllowed(): boolean {
        return this.config.isPresetAddressesAllowed;
    }

    // preset addresses can be editable only for new orders
    get canEditPresetAddresses(): boolean {
        return true;
    }

    onAddressListChanged(addresses: Address[]) {
        this.presetAddresses =  [...addresses];
    }

    toggleWBS() {
        this.isWBSSelected = true;
        this.isInternalOrderSelected = false;
    }

    toggleInternalOrder() {
        this.isInternalOrderSelected = true;
        this.isWBSSelected = false;
    }
}

