import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-clearable-input',
  templateUrl: './clearable-input.component.html',
  styleUrls: ['./clearable-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClearableInputComponent implements OnInit {

  @ViewChild('clearableInput') inputElement: ElementRef;

  _value = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() clear: EventEmitter<any> = new EventEmitter<any>();

  @Input()  defaultValue = '';

  @Input() value = '';

  ngOnInit() {
    this._value = this.value;
  }

  onInput(event: any) {
    this._value = event.target.value;
  }

  onBlur() {
    this.valueChange.emit(this._value);
    // setTimeout(() => {
    //
    // });

  }

  onChange() {
  }

  onClear() {
    if (!!this._value) {
      this._value = '';
      // this.valueChange.emit(this._value);
      this.clear.emit();
    } else {
      this.inputElement.nativeElement.focus();
    }
  }

  get isClearButtonVisible(): boolean {
    return !!this._value;
  }

}
