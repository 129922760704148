import { Component, Input } from '@angular/core';
import { Product } from '../../../../../models/product.model';
import { OrderItem } from '../../../../../models/order-item.model';
import { SKU } from '../../../../../models/sku.model';
import {OrderItemSku} from '../../../../../models/order-item-sku.model';
import {computeAggregatedQuantity} from '../../../../../shared/helpers';
import { Currency } from '../../../../../interfaces/currency';
import { ProductService } from 'app/services/product.service';

@Component({
    selector: 'app-product-order-item-price',
    templateUrl: './product-order-item-price.component.html',
    styleUrls: ['./product-order-item-price.component.scss']
})
export class ProductOrderItemPriceComponent {

    private _orderItem: OrderItem;

    @Input() fontSize = ''; // normal (24px) | small (20px) |  tiny (16px)

    @Input() viewType: string;

    @Input() sku: SKU;

    @Input() product: Product;

    @Input() modal = false;

    @Input() set orderItem(val: OrderItem) {
        this._orderItem = val;
        if (this._orderItem) {
            this.product = this._orderItem.product;
        }
    }


    get price(): number {
        if (!this.product) {
            return 0;
        }

        if (this.sku) {
            return this.sku.getPrice();
        }

        if (!this._orderItem) {
            return this.product.getPrice(this.sku);
        }

        const tiers = ProductService.computePriceTiers(this._orderItem.product, this._orderItem.product.skus[0], this._orderItem.quantity);
        const selectedTier = tiers.priceTiers.filter(x => x.selected);
        if (selectedTier.length > 0) {
            return selectedTier[0].price;
        }

        return this._orderItem.getProductPrice(this.sku);

        // if (this.hasVariations) {
        //     let skyQuantity = 0;
        //     if (this.product.isBuyingWindow) {
        //         skyQuantity = this.product.variationAggregatedQuantity || 1;
        //     } else {
        //         skyQuantity = this.orderItemSKU?.quantity || 1;
        //     }
        //     return  computeProductPrice(this.product, skyQuantity, this.sku);
        // }
        // return computeProductPrice(this.product, this.aggregatedQuantity);
    }

    get pkgQty(): string {
        const pkgQty =  this.currentSKU.packageQuantity;
        if (pkgQty ===  1) {
            return 'Each';
        } else if ( pkgQty > 1) {
            return `${pkgQty} / Pack`;
        }

        return '';

    }

    get aggregatedQuantity(): number {
        let orderedQuantity = 0;
        if (!this._orderItem) {
            orderedQuantity = this.quantityDelta;
        }
        return computeAggregatedQuantity(this.product, this.sku) + orderedQuantity;
    }

    get quantityForTiers(): number {
        if (this.product.hasVariations) {
            if (this.product.isBuyingWindow) {
                return this.product.variationAggregatedQuantity || 1;
            }
            return this.orderItemSKU?.quantity || 0;
        }

        return this.aggregatedQuantity;
    }


    get orderedQuantity(): number {
        return this._orderItem ? this._orderItem.quantity : 0
    }

    get discount(): number {
        if (this._orderItem) {
            return this._orderItem.productDiscount;
        }

        return this.product ? this.product.discount : 0;
    }


    get originalPrice(): number {
        return this.product.getOriginalPrice(this.sku);
    }

    get currentSKU() {
        return this.sku ? this.sku : this.product.skus[0];
    }

    get orderItemSKU(): OrderItemSku {
        if (!this.sku || !this._orderItem) {
            return null;
        }

        return this._orderItem.skus.find( s => s.sku_id === this.sku.id);
    }

    get classes(): string {
        return `${this.orientation} ${this.fontSize}`;
    }

    get orientation(): string {
        return  this.viewType === 'grid-item' ? 'horizontal' : 'vertical';
    }

    get hasVariations(): boolean {
        return this.product.hasVariations;
    }

    get showPriceVariations(): boolean {
        return this.viewType === 'grid-item' && this.hasVariations;
    }

    get priceVariations(): {min?: number, max?: number} {
        return this.product.priceVariations;
    }

    get quantityDelta(): number {
        return this._orderItem?.quantityDelta || 0;
    }

    get canShowPriceTiers(): boolean {
        return !(this.product.hasVariations && this.viewType === 'grid-item');
    }

    get currency(): Currency {
        if (this.orderItem?.product) {
            return this.orderItem.product.currency;
        }

        if (this.product) {
            return this.product.currency;
        }

        return null;
    }
}
