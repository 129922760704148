import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {debounceTime, distinctUntilChanged, switchMap, takeWhile, tap} from 'rxjs/operators';
import {of, Subject} from 'rxjs';

import {Address} from '../../../../models/address.model';
import {
  flatternSortedAddressesByWslr,
} from '../../../../shared/helpers';
import {AddressService} from '../../../../services/address.service';
import {AuthService} from '../../../../services/auth.service';
import {ModalService} from '../../../../services/modal.service';
import {MobileCheckServiceService} from '../../../../services/mobile-check-service.service';
import {isEmptyArray} from '../../../../shared/utils';

const DROPDOWN_WIDTH = 300;

@Component({
  selector: 'app-preset-address-item',
  templateUrl: './preset-address-item.component.html',
  styleUrls: ['./preset-address-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PresetAddressItemComponent implements  OnInit, OnDestroy, AfterViewInit {
  @Input() address: Address;
  @Input() showAddButton = false;
  @Input() disabled  = false;

  private accountAddresses: Address[] = [];
  private alive = true;

  addr_id: number;
  addressesSubject: Subject<string> = new Subject<string>();
  addressesLoading = false;
  computedAddressList: Address[] = [];
  itemsTemplateHeader = 'Your Addresses';

  private initialized;

  @ViewChild('select', { static: false }) addressSelector;

  @Input() className = '';

  // output event when address has been selected
  @Output() addressUpdated = new EventEmitter<Address>();
  @Output() addressRemoved = new EventEmitter<void>();
  @Output() anotherAddressAdded = new EventEmitter<void>();


  constructor(private addressService: AddressService, private authService: AuthService,
              private modalService: ModalService, private mobileCheck: MobileCheckServiceService) {

  }

  onSelect(data) {
    if (data instanceof Event) {
      return;
    }
    this.addressUpdated.emit(data);
  }

  onCloseDropdown() {
    // reset search items to default state
    this.computeAddressList(this.accountAddresses);
  }

  computeAddressList(addresses: Address[] = []) {
    this.computedAddressList = flatternSortedAddressesByWslr([...addresses], this.authService.user.entity_id);

    if (this.address) {
      this.addr_id = this.address.id;
      // check if address is in the list
      const found = this.computedAddressList.find( a => a.id === this.address.id);
      if (!found) {
        this.computedAddressList = [this.address, ...this.computedAddressList];
      }

    } else {
      if (this.computedAddressList.length > 0) {
        this.addr_id = this.computedAddressList[0].id;
      } else {
        this.addr_id = null;
      }
    }
  }

  ngOnInit() {

    if (isEmptyArray(this.accountAddresses)) {
      this.accountAddresses = this.addressService.addresses;
    }

    this.computeAddressList(this.accountAddresses);

    this.addressesSubject.pipe(
      takeWhile( () => this.alive),
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => this.addressesLoading = true),
      switchMap(term => {
        if (!term) {
          return of(this.accountAddresses);
        }

        return this.addressService.search(term);

      })
    ).subscribe( (addresses: Address[]) => {
      this.addressesLoading = false;
      this.computeAddressList(addresses || []);
    });

  }

  ngAfterViewInit() {
    setTimeout(() => this.initialized = true);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  // get addressLabel() {
  //   if (!this.delivery) {
  //     return '';
  //   }
  //
  //   return computeShipmentLabel(this.delivery, this.attention);
  // }


  get canAddAddresses(): boolean {
    return this.authService.canAddAddress;
  }



  addAddress() {
    this.modalService.editAddress(null, true).then( result => {
      if (result) {
        this.accountAddresses = this.addressService.addresses;
        this.computeAddressList(this.accountAddresses);
        this.addr_id = result.id;
        this.addressUpdated.emit(result);
      }
    })
    this.addressSelector.close();
    return false;

  }


  get isEmployee(): boolean {
    return this.authService.isEmployee;
  };



  get mobile(): boolean {
    return this.mobileCheck.mobile;
  }

  get  dropDownClasses(): string {
    if (!this.mobile || !this.initialized) {
      return  `${this.className}`;
    }

    let leftAligned = false;
    if (this.addressSelector ) {
      const rect = this.addressSelector.element.getBoundingClientRect();
      leftAligned = rect.right >= DROPDOWN_WIDTH;
    }

    return `${this.className} ${this.mobile ? 'mobile ' : 0} ${leftAligned ? 'left-aligned' : ''}`;
  }

  removeAddress() {
    this.addressRemoved.emit();
  }

  addAnotherAddress() {
    this.anotherAddressAdded.emit();
  }
}
