import {Injectable} from '@angular/core';


export const U_TOKEN = 'u-token';
export const U_SSO  = 'u-sso';
export const U_SSO_URL = 'u-sso-url';
export const U_UTC_OFFSET = 'u-utc-offset';
export const REDIRECT_POST_LOGIN = 'redirect-post-login';
export const LOGIN_ERROR = 'login-error';
export const GRID_VIEW = 'grid-view';
export const ACTIVE_ORDER_TAB = 'active-order-tab';


export const FIXED_HEADER_ENABLED = 'fixed_header_enabled';
export const HEADER_HEIGHT = 'header_height';

export const ACTIVE_ORDER_WINDOW_ID = 'ACTIVE_ORDER_WINDOW_ID';

@Injectable()
export class LocalStorageService {

    getItem(key: string) {
        return localStorage.getItem(key);
    }

    setItem (key: string, data: string) {
        localStorage.setItem(key, data);
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
    }

    clear() {
        localStorage.clear();
    }
}
