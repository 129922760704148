<ng-container *ngIf="!showAsTable; else tableLayout">
  <div *ngIf="showTiers" ngbDropdown  #drop1 (openChange)="checkDropDown($event, drop1)"
       placement="{{placementPosition}}" class="pricing-tiers--box">
    <a  class="dropdown-toggle no-caret pl-2 pr-2" ngbDropdownToggle>
      <i class="fal fa-info-circle"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-right pricing-tiers__container"
         aria-labelledby="product-pricing" ngbDropdownMenu [ngStyle]="minWidthStyle" >
      <div class="dropdown-menu-content pricing-tiers" >
        <strong class="d-block font-5">PRICING</strong>
        <ng-container *ngIf="hasPricingTiers; else runOnRange">
          <ul class="mb-0" [ngClass]="{'with-scroll': hasScroll}">
            <li *ngFor="let priceTier of priceTiers" [ngClass]="{'is-bold': priceTier.selected}">
              <div >{{priceTier.range}}</div>
              <div class="d-flex">
                <app-currency-price [price]="priceTier.price" [currency]="priceTier.currency"></app-currency-price>/{{priceTier.packageType}}
              </div>
            </li>
          </ul>
        </ng-container>
      </div>
    </div>
  </div>

</ng-container>

<ng-template #runOnRange>
  <div [ngClass]="{'with-scroll': hasScroll}">
    <table class="price-run-on-range" >
      <thead>
      <tr>
        <th>QTY</th>
        <th>Price</th>
        <th>Each<br>Additional</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let priceTier of pricesRunOnRange" [ngClass]="{'is-bold': priceTier.selected}">
        <td>{{priceTier.quantity}}</td>
        <td>
          <div class="d-flex">
            <app-currency-price [price]="priceTier.pricing" [currency]="priceTier.currency"></app-currency-price>
          </div>
        </td>
        <td>
          <div class="d-flex">
            <app-currency-price [fractionDigits]="6" [price]="priceTier.each_additional" [currency]="priceTier.currency" ></app-currency-price>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #tableLayout>
  <div *ngIf="showTiers">
    <strong>PRICING TIERS</strong>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Range</th>
        <th scope="col">Price</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="hasPricingTiers">
        <tr *ngFor="let priceTier of priceTiers">
          <td>{{priceTier.range}}</td>
          <td class="d-flex"><app-currency-price [price]="priceTier.price" [currency]="priceTier.currency"></app-currency-price></td>
        </tr>
      </ng-container>
      <ng-container *ngIf="hasPriceRunOnRange">
        <tr *ngFor="let priceTier of pricesRunOnRange">
          <td>{{priceTier.quantity}}</td>
          <td class="d-flex"><app-currency-price [price]="priceTier.pricing" [currency]="priceTier.currency"></app-currency-price></td>
        </tr>
      </ng-container>
      </tbody>
    </table>

  </div>
</ng-template>
