import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { register as registerSwiperElement } from 'swiper/element/bundle';

import { ThemeModule } from '../../theme/theme.module';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';

import { NotificationStore } from '../../stores/notification.store';

import { FilterComponent } from '../products/filter/filter.component';
import { ProductsGridRelatedComponent } from '../products/grid-related/grid-related.component';
import { ProductComponent } from '../product/product.component';
import { HeroComponent } from '../hero/hero.component';

import { NgxGalleryModule } from '../../ngx-gallery';

// Init Swiper
registerSwiperElement()

@NgModule({
    imports: [
        CommonModule,
        ThemeModule,
        DirectivesModule,
        PipesModule,
        NgbModule,
        FormsModule,
        RouterModule,
        NgxGalleryModule,
        NgSelectModule,
    ],
    declarations: [
        FilterComponent,
        ProductComponent,
        HeroComponent,
        ProductsGridRelatedComponent,
    ],
    providers: [
        NotificationStore
    ],
    exports: [
        CommonModule,
        FilterComponent,
        ProductComponent,
        HeroComponent,
        ProductsGridRelatedComponent,
    ]
})
export class SharedProductsModule {
}
