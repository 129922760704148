import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Order } from '../models/order.model';
import { Response } from '../models/response.model';
import { catchError, map, take, tap } from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { AppSettings } from '../app.settings';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { OrderWindow } from '../models/order-window.model';
import { CacheModel } from '../models/cache.model';
import { OrderItem } from '../models/order-item.model';
import {findDetailedErrorMessage} from '../shared/helpers';
import {CREDIT_CARD_PAYMENT_ERROR_CODE, DOA_EXCEED_LIMIT} from '../constants/error.codes';
import {IError} from '../models/error.model';
import {CostCenter} from '../models/cost-center.model';
import {OrderHistory} from '../models/order-history.model';
import {AvailableOrderWindow} from '../interfaces/order-window.interface';
import {isEmptyArray} from '../shared/utils';

const BASE_URL = '/order';
import {API_URL} from '../constants/api-urls';

@Injectable()
export class ApiOrderService {
    readonly apiURL: string;
    readonly apiV2URL: string;
    private cacheKey = 'orders';
    protected cache: CacheModel = null;


    private windowFetching = false;

    constructor(private http: HttpClient, private appSettings: AppSettings, private toastr: ToastrService) {
        this.apiURL = this.appSettings.settings.apiBaseURL + BASE_URL;
        this.apiV2URL =  `${API_URL}${BASE_URL}`;
        this.cache = new CacheModel();
    }

    fetchOrder(id): Observable<Order> {
        return this.http.get<Response>(this.apiV2URL + '/' + id)
            .pipe(
                map(response => {
                    if (response.data) {
                        return new Order(response.data);
                    }
                    return null;
                }),
                catchError(this.handleHttpError('OrderService::getOrder', null))
            );
    }


    fetchOrderWindows(fetchAllOrderWindows = false): Observable<OrderWindow[]> {

        const key = 'ow-' + (fetchAllOrderWindows ? 'all' : 'active');

        if (this.cache.has(key)) {
            return of(this.cache.getItems(key));
        }
        if (this.windowFetching) {
            setTimeout(() => {
                return of(this.cache.getItems(key));
            }, 1000);
            return;
        }
        this.windowFetching = true;

        const date = moment().subtract(1, 'years').format('YYYY-MM-DD');
        const url = `${API_URL}/orders/windows${fetchAllOrderWindows ? '/' + date : ''}`;

        return this.http.get<Response>(url)
            .pipe(
                map( response => {
                    if (Array.isArray(response.data)) {
                        const orderWindows: OrderWindow[] = response.data.map( item => new OrderWindow(item));
                        this.cache.setItems(key, orderWindows);
                        return orderWindows;
                    }
                    return [];
                }),
                tap( () => {
                   this.windowFetching = false;
                }),
                catchError(this.handleHttpError('OrderService::getOrderWindows', []))
            );

    }


    fetchActiveOrders(orderWindow: OrderWindow): Observable<Order[]> {
        const url = `${API_URL}/orders/get-active/${orderWindow ? orderWindow.id : ''}`;
        return this.http.get<Response>(url)
            .pipe(
                map(response => {
                    if (response.data) {
                        return response.data.map( item => new Order(item));
                    }

                    return of([]);
                }),
                catchError(this.handleHttpError('OrderService::getActiveOrders', []))
            );
    }

    fetchOrderHistory(filter, paging: string = '10-0'): Observable<{orders: OrderHistory[], count: number}> {
        const headers: HttpHeaders = new HttpHeaders({
            'Range': paging,
        });

        return this.http.post<Response>(this.apiURL + 's', filter, {observe: 'response', headers: headers})
            .pipe(
                map(httpResponse => {
                    const response = new Response(httpResponse.body);
                    const count: number = +httpResponse.headers.get('X-Count');
                    if (Array.isArray(response.data)) {
                        return {orders: response.data.map( i => new OrderHistory(i)), count};
                    }
                    return {orders: [], count: 0};
                }),
                catchError(this.handleHttpError('OrderService::fetchOrderHistory', null))
            );
    }

    // getOrderHistoryCount(type: string): number {
    //     return (this.cache.has(type + '-count')) ? this.cache.get(type + '-count') : 0;
    // }

    setOrderAttribute(orderID: number, attr: string, value: any): Observable<boolean> {
        const url = `${this.apiV2URL}/${orderID}/attr/${attr}`;
        return this.http.post<Response>(this.apiURL + '/' + orderID + '/attr/' + attr, value || {},
            {headers: this.xsrfTokenHeader})
            .pipe(
                map(response => {
                    return response.data;
                }),
                catchError(this.handleHttpError('OrderService::setOrderAttribute', false))
            );
    }

    updateCostCenter(orderID: number,  costCenter: CostCenter): Observable<boolean | IError> {
        return this.http.post<Response>(this.apiURL + '/' + orderID + '/attr/cost_center', costCenter || {},
            {headers: this.xsrfTokenHeader})
            .pipe(
                map(response => {
                    return response.data;
                }),
                catchError(this.handleErrorWithCodes())
            );
    }

    setOrderAttributes(orderId: number, attributes: any): Observable<boolean> {
        const url = `${this.apiV2URL}/${orderId}/attrs`;
        return this.http.post<Response>(url, attributes,
            {headers : this.xsrfTokenHeader})
            .pipe(
                map(response => {
                    return response.data;
                }),
                catchError(this.handleHttpError('OrderService::setOrderAttributes', false))
            );
    }

    removeCreditCardFromOrder(order: Order): Observable<boolean> {
        const url = `${this.apiURL}/${order.id}/attr/credit_card`;
        return  this.http.delete<Response>(url).pipe(
            map(response => {
                return response.data;
            }),
            catchError(this.handleHttpError('OrderService::removeCreditCardFromOrder', false))
        );
    }


    updateOrderEntity(orderId: number, entityId: number): Observable<Order> {
      const url = `${this.apiURL}/${orderId}/entity/${entityId}`;
        return this.http.post<Response>(`${this.apiURL}/${orderId}/entity/${entityId}`, '').pipe(
            map((response: Response) => {
                return new Order(response.data);
            }),
            catchError(this.handleHttpError('OrderService::updateEntity', null))
        );
    }


    makeActiveOrder(orderID: number): Observable<boolean> {
        return this.http.put<Response>(this.apiURL + '/' + orderID + '/active', {})
            .pipe(
                map(response => {
                    return true;
                }),
                catchError(this.handleHttpError('OrderService::setActive', false))
            );
    }

    deleteOrder(orderID: number): Observable<boolean> {
        return this.http.delete<Response>(this.apiURL + '/' + orderID)
            .pipe(
                map(response => {
                    return response.data;
                }),
                catchError(this.handleHttpError('OrderService::delete', false)),
            );
    }

    getInvoice(orderID: number, type: string = ''): Observable<any> {
        return this.http.get<Response>(this.apiURL + '/' + orderID + '/invoice' + type)
            .pipe(
                map(response => {
                    return response.data;
                }),
                catchError(this.handleHttpError('OrderService::getInvoice' + type, []))
            );
    }


    getItem(orderID: number, itemID: number): Observable<OrderItem> {
        return this.http.get<Response>(this.apiURL + '/' + orderID + '/item/' + itemID)
            .pipe(
                map(response => {
                    return new OrderItem(response.data);
                }),
                catchError(this.handleHttpError('OrderService::getItem', null))
            );
    }

    createItem(order: Order, item: OrderItem): Observable<OrderItem> {
        return this.http.post<Response>(this.apiV2URL + '/' + order.id + '/item', item.prepItemToSave(),
            {headers: this.xsrfTokenHeader})
            .pipe(
                map(response => {

                    if (response.data) {
                        const orderItem = order.createOrderItem(response.data);
                        order.updateOrderItems(orderItem);
                        return  orderItem;
                    }

                    return null;
                }),
                catchError(this.handleHttpError('OrderService::createItem', null))
            );
    }

    updateItem(order: Order, itemID: number, item: OrderItem): Observable<Order> {
        return this.http.put<HttpResponse<Response>>(this.apiV2URL + '/' + order.id + '/item/' + itemID, item.prepItemToSave(),
            {observe: 'response', headers: this.xsrfTokenHeader})
            .pipe(
                map(httpResponse => {

                    const response = new Response(httpResponse.body);
                    if (response.data) {
                        const orderItem = order.createOrderItem(response.data);
                        order.updateOrderItems(orderItem);

                        if (httpResponse.headers.get('addr_upload_file_deleted') === '1') {
                            orderItem.addr_upload_file = null;
                        }
                        if (httpResponse.headers.get('wslr_upload_file_deleted') === '1') {
                            orderItem.wslr_upload_file = null;
                        }

                    }
                    return order;
                }),
                catchError(this.handleHttpError('OrderService::updateItem', null))
            );
    }

    deleteRestrictedOrderItems(orderId: number): Observable<Order> {
        const url = `${this.apiURL}/${orderId}/restricted-items`;

        return this.http.delete<HttpResponse<Response>>(url, {observe: 'response'})
            .pipe(
                map(httpResponse => {
                    const response = new Response(httpResponse.body);
                    if (response.data) {
                        const retOrder = new Order(response.data);
                        return retOrder;
                    }
                    return  null;
                }),
                catchError(this.handleHttpError('OrderService::deleteRestrictedOrderItems', null))
            );
    }
    deleteItem(order: Order, itemID: number): Observable<Order> {
        return this.http.delete<HttpResponse<Response>>(this.apiURL + '/' + order.id + '/item/' + itemID, {observe: 'response'})
            .pipe(
                map(httpResponse => {
                    const response = new Response(httpResponse.body);
                    if (response.data) {

                        order.updateOrderItems(itemID);

                        // if (httpResponse.headers.get('addr_upload_file_deleted') === '1') {
                        //     order.attr_addr_upload_file = null;
                        // }
                        // if (httpResponse.headers.get('wslr_upload_file_deleted') === '1') {
                        //     order.attr_wslr_upload_file = null;
                        // }

                        return order;
                    }

                    return  null;
                }),
                catchError(this.handleHttpError('OrderService::deleteItem', null))
            );
    }

    checkout(orderId: number): Observable<boolean | IError> {
        //const url = this.apiURL + '/' + orderId + '/checkout';
        const urlV2  =   `${this.apiV2URL}/${orderId}/checkout`;
        return this.http.post<Response>(urlV2, '')
            .pipe(
                map(response => {
                    return response.data;
                }),
                catchError(this.handleCheckoutError('OrderService::checkout', false))
            );
    }

    // Order level methods
    createOrder(orderBody: any): Observable<Order> {
        return this.http.post<Response>(this.apiURL, orderBody)
            .pipe(
                map(response => {
                    if (response.data) {
                        return new Order(response.data);
                    }
                    return null;
                }),
                catchError(this.handleHttpError('OrderService::createOrder', null))
            );
    }


    // promocode

    applyPromoCode(order: Order, promoCode: string): Observable<Order> {

        return this.http.post<Response>(`${this.apiURL}/${order.id}/coupon`, {code: promoCode},
            {headers: this.xsrfTokenHeader})
            .pipe(
                map(response => {
                    if (response.data) {
                        return  new Order(response.data);
                    }
                    return null;
                }),
                catchError(this.handleHttpError('OrderService::applyPromoCode', null))
            );
    }


    revokePromoCode(order: Order): Observable<Order> {

        return this.http.delete<Response>(`${this.apiURL}/${order.id}/coupon`)
            .pipe(
                map(response => {
                    if (response.data) {
                        return new Order(response.data);
                    }
                    return null;
                }),
                catchError(this.handleHttpError('OrderService::revokePromoCode', null))
            );

    }

    public getAvailableOrderWindows(): Observable<AvailableOrderWindow[]> {
        return this.http.get<Response>(`${this.apiURL}s/available-windows`).pipe(
            map( response => {
                if (!isEmptyArray(response.data)) {
                    return response.data;
                }
                return [];
            }),
            catchError(this.handleHttpError('OrderService::getAvailableOrderWindows', []))
        )
    }

    clear() {
        this.cache.clear();
    }

    private handleErrorWithCodes() {
        return (error: any): Observable<IError> => {

            const errorMessage = findDetailedErrorMessage(error);
            if (errorMessage) {
                this.toastr.error(errorMessage);
            }

            const result: IError = {};
            const errorObj = error.error;
            if (errorObj?.code) {
                result.errorCode = errorObj?.code;
            }

            if (errorObj?.data) {
                result.data = errorObj?.data;
            }

            return of(result);
        }
    }

    private handleCheckoutError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<any> => {
            console.log(operation + ' failed', error);
            const errorObj = error.error;
            if (errorObj?.code === DOA_EXCEED_LIMIT) {
                return of({errorCode: errorObj.cod})
            }

            if (errorObj?.code === CREDIT_CARD_PAYMENT_ERROR_CODE) {
                // error should be displayed as modal dialog
                return of ({errorCode: errorObj.code, data: {transactionId: errorObj.data?.transaction_id || 'N/A'}});
            }
            const errorMessage = findDetailedErrorMessage(error);
            if (errorMessage) {
                this.toastr.error(errorMessage);
            }
            return of(result as T);
        };
    }

    private handleHttpError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            const errorMessage = findDetailedErrorMessage(error);
            if (errorMessage) {
                this.toastr.error(errorMessage);
                console.log(operation + ' failed', error);
            }
            return of(result as T);
        };
    }

    private get xsrfTokenHeader() {
        return new HttpHeaders({'x-xsrf-token': ''});
    }

}
