import {Injectable} from '@angular/core';
import {map, switchMap, tap} from 'rxjs/operators';
import {forkJoin, from, Observable, of} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {observable} from 'mobx';

import {OrderService} from './order.service';
import {ModalService} from './modal.service';
import {OrderItem} from '../models/order-item.model';
import {Order} from '../models/order.model';
import {AuthService} from './auth.service';
import {AlertType} from '../enums/alert-type';
import {Address} from '../models/address.model';
import {Product} from '../models/product.model';
import {AddressService} from './address.service';
import {BudgetService} from './budget.service';
import {OrderItemDelivery} from '../models/order-item-delivery.model';
import {
    computeAvailableAddresses,
    isPaymentMethodDefined,
    validateOrderItem,
    validateWbsForUnique,
} from '../shared/helpers';
import {ProductService} from './product.service';
import {isEmptyArray} from '../shared/utils';
import {Budget} from '../models/budget.model';
import {CREDIT_CARD_PAYMENT_ERROR_CODE} from '../constants/error.codes';
import {PaymentService} from './payments.service';
import {MobileCheckServiceService} from './mobile-check-service.service';
import {Router} from '@angular/router';
import {OrderWindow} from '../models/order-window.model';
import {ECOMMERCE_TYPE, PREORDER_TYPE} from '../constants/order-types';
import {EntityService} from './entity.service';
import {RecommendedProductsService} from './recommended-products.service';
import {ConfigService} from './config.service';
import {MultiTenantService} from './multi-tenant.service';

@Injectable()
export class OrderItemService {

    @observable updating = false;

    constructor(private orderService: OrderService,
                private productService: ProductService,
                private modalService: ModalService,
                private authService: AuthService,
                private addressService: AddressService,
                private budgetService: BudgetService,
                private toastr: ToastrService,
                private paymentService: PaymentService,
                private mobileCheck: MobileCheckServiceService,
                private router: Router,
                private entityService: EntityService,
                private recommendedService: RecommendedProductsService,
                private configService: ConfigService,
                private multiTenantService: MultiTenantService) {
    }

    private checkPaymentDefined(order: Order, showOrderSettings = false): Observable<Order> {
        if (!order || !order.id || order.limited) {
            return of(null);
        }


        if (order.isNotReal) {
            return this.orderService.createOrder(order.type).pipe(
                switchMap( createdOrder => {

                    if (!isPaymentMethodDefined(createdOrder, this.authService.user,
                      this.authService.canShowSAP && this.configService.showBillingInfo)) {

                        return from(this.modalService.showSAPModal(createdOrder, showOrderSettings, true)).pipe(map(retOrder => {
                            // check order payment after order settings update
                            if (isPaymentMethodDefined(retOrder, this.authService.user,
                              this.authService.canShowSAP && this.configService.showBillingInfo)) {
                                return retOrder;
                            }
                            return null;
                        }));

                    } else {
                        return of(createdOrder);
                    }

                }

            ),
            tap((retOrder: Order) => {
                if (retOrder) {
                    // remove fake order
                    this.orderService.updateOrderList(-1);
                }
            }))
        }


        // check if order has cost center
        if (!isPaymentMethodDefined(order, this.authService.user, this.authService.canShowSAP && this.configService.showBillingInfo)) {

            return from(this.modalService.showSAPModal(order, showOrderSettings, true)).pipe(map(retOrder => {
                // check order payment after order settings update
                if (isPaymentMethodDefined(retOrder, this.authService.user,
                    this.authService.canShowSAP && this.configService.showBillingInfo)) {
                    return retOrder;
                }
                return null;
            }));

        } else {
            return of(order);
        }
    }


    public saveOrderItem(order: Order, orderItem: OrderItem, skipNewOrderItem = false, originalOrderItem: OrderItem = null):
        Observable<{ purchaseResult: { added: boolean, updated: boolean, deleted: boolean, hasError: boolean}
                    order: Order,
                    orderItem: OrderItem,
                    budgets?: Budget[]}> {

        if (!order || !orderItem || !this.authService.canEditOrder(order)) {
            return of(null);
        }

        orderItem.calculateQuantity();
        const copiedItem = new OrderItem(originalOrderItem ? originalOrderItem : orderItem);

        const purchaseResult = {added: false, updated: false, deleted: false, hasError: false};

        // validate diageo - if  wbs code is defined
        if ( this.multiTenantService.canShowWBS) {
            if (!order.isNotReal && !order.isWBSDefined && !order.isInternalOrderDefined) {
                // check if  some delivery has wbs
                const hasWbs = orderItem.deliveries.some( d => !!d.internalOrder);
                if (!hasWbs) {
                    this.toastr.warning('Please define WBS or Internal Order in the order settings.');
                    return of(null);
                }
            }
        }

        const errorMsg  = validateOrderItem(orderItem)
        if (errorMsg) {
            this.toastr.warning(errorMsg);
            return of(null);
        }


        return this.checkPaymentDefined(order).pipe(
            switchMap(result => {
                order = result;
                if (!order) {
                    return of(null);
                }


                this.updating = true;

                // orderItem = this.budgetService.computeBudgetAmount(orderItem);

                if (orderItem.id) {

                    if (orderItem.quantity === 0) {
                        const product = new Product(orderItem.product);
                        // delete item
                        return this.orderService.deleteOrderItem(order, orderItem).pipe(
                            switchMap(retOrder => {
                                if (!retOrder) {
                                    // delete failed - restore item
                                    purchaseResult.hasError = true;
                                    return of({purchaseResult, orderItem: copiedItem, order});
                                }
                                purchaseResult.deleted = true;
                                const wslrEntityId = retOrder.isWSLR ? retOrder.entity_id : 0;

                                return this.productService.getProduct(product.id, wslrEntityId, false, true).pipe( map( retProduct => {
                                    // setup new order item
                                    this.getProductPurchasedDetails(product.id);
                                    const newOrderItem = this.setupOrderItem(order, retProduct || product);
                                    this.toastr.success('Your item has been deleted.');
                                    return {purchaseResult, orderItem: newOrderItem, order};
                                }));
                            })
                        );
                    } else {
                        // update item
                        const orderItemId = orderItem.id;
                        const budgetId = orderItem.financials_total?.budget_id;
                        return this.orderService.updateOrderItem(order, orderItem).pipe(
                            map(retOrder => {
                                if (retOrder) {
                                    purchaseResult.updated = true;
                                    const updatedOrderItem = retOrder.items.find(i => i.id === orderItemId);
                                    updatedOrderItem.changed = false;
                                    updatedOrderItem.financials_total.budget_id = budgetId;
                                    this.productService.populatedUpdatedProduct(updatedOrderItem.product);
                                    this.getProductPurchasedDetails(updatedOrderItem?.product?.id)
                                    this.toastr.success('Your item has been updated');
                                    return {purchaseResult, orderItem: updatedOrderItem, order};
                                } else {
                                    purchaseResult.hasError = true;
                                    return {purchaseResult, orderItem: copiedItem, order};
                                }
                            })
                        );
                    }

                } else {

                    return this.orderService.createOrderItem(order, orderItem).pipe(
                        map(retOrderItem => {
                            if (retOrderItem) {
                                retOrderItem.changed = false;
                                purchaseResult.added = true;
                                this.productService.populatedUpdatedProduct(retOrderItem?.product);
                                this.getProductPurchasedDetails(retOrderItem?.product?.id);
                                this.toastr.success('Your item has been added')
                            } else {
                                purchaseResult.hasError = true;
                            }
                            return {purchaseResult, orderItem: retOrderItem, order};
                        })
                    );
                }
            }),
            switchMap( retVal => {
                if (!retVal) {
                    return of(retVal);
                }
                return this.budgetService.fetchBudgetItemForOrderItem(retVal?.orderItem).pipe( map(retOrderItem => {
                    retVal.orderItem = retOrderItem;
                    retVal.budgets = this.budgetService.getBudgetsForOrderItem(retVal?.orderItem);
                    return retVal;
                }));
            }),
            tap( () => {
                this.updating = false;
            })
        );


    }

    public getOrderItemFor(order: Order, orderItem: OrderItem, product: Product): OrderItem {
        if (orderItem) {
            return orderItem;
        }
        return this.setupOrderItem(order, product);
    }


    public editOrderItemModal(order: Order, orderItem: OrderItem, showOrderSelector = false): Promise<OrderItem> {

        if (this.mobileCheck.mobile) {
            // redirect to product page
            const product = orderItem.product;
            let url = product.productURL;
            if (product.isBuyingWindow && product.window_id !== order.window_id) {
                url = product.getProductUrlForOrderWindow(order.window_id)
            }
            return this.router.navigate([url]).then( () => orderItem);
        }

        let edittedOrderItem: OrderItem = new OrderItem(orderItem);

        const checkForChanges = (updatedOrderItem) => {

            if (!this.authService.canEditOrder(order)) {
                return  true;
            }

            if (!updatedOrderItem.changed) {
                return true;
            }

            if (this.hasFailedTransactions) {
                return true;
            }

            const noShippingMethods = updatedOrderItem.deliveries.some((delivery) => delivery.method === '');
            if (noShippingMethods) {
                return true;
            }

            if (this.multiTenantService.canShowWBS && validateWbsForUnique(order, updatedOrderItem)) {
                return true;
            }

            return this.showSaveConfirmation().then((result) => {
                if (!result) {
                    return true;
                }

                const itemFromOrder = order.items?.find(x => x);
                
                if (itemFromOrder && itemFromOrder.product.currency.code !== updatedOrderItem.product.currency.code){
                    this.toastr.warning('This item is not in the same currency. Please create a new cart');
                    return true;
                }

                return this.saveOrderItem(order, updatedOrderItem).pipe(map((updateResut) => {
                    edittedOrderItem = updateResut.orderItem ? updateResut.orderItem : null;
                    return true;
                })).toPromise()
            });

        };

        return this.modalService.editOrderItemModal(order, edittedOrderItem, checkForChanges, showOrderSelector).then(() => {
            return edittedOrderItem;
        }).catch(() => {
            return edittedOrderItem;
        });
    }

    public showSaveConfirmation(): Promise<boolean> {
        return this.modalService.showAlert({
            type: AlertType.Confirm,
            class: 'modal-sm',
            headerClass: 'state',
            title: 'Save changes',
            message: 'Your have made changes to your order that are not saved.',
            actionLabel: 'Save',
            cancelLabel: 'Skip & Continue'
        });
    }

    public setupOrderItem(order: Order, product: Product): OrderItem {

        if (!order || !product) {
            return null;
        }

        const userRoles = {canOrder: true, isEmployee: this.authService.isEmployee};
        const presetAddresses = order.presetAddresses;
        const orderItem = order.getItemForProduct(product, {
            defaultDelivery: null,
            userRole: userRoles,
            presetAddresses: presetAddresses,
        });


        if (this.canOrder(order)) {

            // Select first address
            if (orderItem.deliveries.length > 0 && !orderItem.deliveries[0].street_1) {
                if (order.isWSLR) {
                    // set default WLS address
                    const entityAddress = Address.fromEntityLocation(order.entity.getDefaultShippingLocation());
                    if (entityAddress) {
                        orderItem.deliveries[0].setAddress(entityAddress, this.authService.user, order.isCustom, userRoles);
                    }
                    // this.setInitialDeliveryAddress(this._orderItem.deliveries[0], entityAddress);
                } else  {
                    // set user's default address
                    const availableAddresses = computeAvailableAddresses(this.addressService.addresses, order, orderItem);
                    if (!isEmptyArray(availableAddresses)) {
                        orderItem.deliveries[0].setAddress(availableAddresses[0], this.authService.user, order.isCustom, userRoles);
                    }
                }
            }
            this.findBudget(orderItem);
        }

        return orderItem;
    }

    private findBudget(orderItem: OrderItem) {
        const budgetId = orderItem?.financials_total?.budget_id;
        if (budgetId === -1) {
            orderItem.budgetItem = this.budgetService.emptyBudget;
        } else if (orderItem.financials_total.budget_id > 0) {
            orderItem.budgetItem = this.budgetService.budgets.find( b => b.id === orderItem.financials_total.budget_id);
        } else {
            const hardLimitBudget = this.budgetService.getHardlimitBudgetForProduct(orderItem.product);
            if (hardLimitBudget) {
                orderItem.financials_total.budget_id = hardLimitBudget.id;
                orderItem.budgetItem = hardLimitBudget;
            }
        }
    }

    addAnotherAddress(orderItem: OrderItem, order: Order): OrderItem {
        if (!orderItem || !this.canOrder(order)) {
            return orderItem;
        }

        const availableAddresses = computeAvailableAddresses(this.addressService.addresses, order, orderItem);

        if (isEmptyArray(availableAddresses)) {
            return orderItem;
        }

        const firstAvailableAddress = availableAddresses[0];
        const  shippingMethod  =  orderItem.product.findFirstAvailableShippingMethod(firstAvailableAddress.country_code);

        // check if this address has pendingDelete = true otherwise create a new one
        const newDelivery = new OrderItemDelivery({
                type: 'SHIPPING',
                quantity: 0,
                data: {},
                method: shippingMethod
            });
        newDelivery.setAddress(firstAvailableAddress, this.authService.user, order.isCustom,
            {canOrder: true, isEmployee: this.authService.isEmployee});
        orderItem.updateDelivery(newDelivery, order.isOnDemand);
        return  new OrderItem(orderItem);
    }


    private updateBudget(orderItem: OrderItem): Observable<Budget[]> {
        return this.budgetService.updateBudget(orderItem);
    }

    public calculateDecisionPoints(order: Order, orderItem: OrderItem, quantity): Observable<OrderItem> {

        if (!this.canOrder(order)) {
            return  of(orderItem);
        }

        return this.productService.calculateDecisionPoints(order.attr_decision_point_id, orderItem.product.id, quantity).pipe(
            map( result => {

                if (isEmptyArray(result)) {
                    return orderItem;
                }

                result.forEach( i => {
                   const address = new Address(i.address);

                   let delivery = orderItem.findDelivery(address);
                   if (delivery) {
                       delivery.quantity = i.quantity;
                   } else {
                       const shippingMethod = orderItem.product.findFirstAvailableShippingMethod(address.country_code);
                       delivery = new OrderItemDelivery({
                           quantity: i.quantity,
                           type: 'SHIPPING',
                           method: shippingMethod,
                           is_uneligible: i.is_uneligible});
                       delivery.setAddress(address, this.authService.user, false,
                           {canOrder: true, isEmployee: this.authService.isEmployee});
                   }

                   orderItem.updateDelivery(delivery, order.isOnDemand);
                });
                return orderItem;
            })
        );
    }

    public checkout(order: Order): Observable<Order> {
        if (!order) {
            return of(order);
        }
        return this.orderService.checkout(order, true).pipe( map( retOrder => {
            if (retOrder) {
                if (retOrder.error?.errorCode === CREDIT_CARD_PAYMENT_ERROR_CODE) {
                    const transactionId = retOrder.error.data.transactionId;
                    this.modalService.showCreditCardPaymentFailed(order, transactionId);
                }
            }
            return  retOrder;

        }))
    }

    public get hasFailedTransactions(): boolean {
        return this.paymentService.hasFailedTransactions;
    }


    public canOrder(order: Order): boolean {
        return  this.authService.canEditOrder(order);
    }

    public hasAccessToProduct(order: Order, product: Product): Observable<boolean> {
        if (!order || !order.loaded || !product) {
            return of(false);
        }

        // check only for wslr order
        if (!order.isWSLR) {
            return of(true);
        }

        return of(true);
        // return this.productService.getProduct(product.id, order.entity_id, false, true).pipe(
        //     map( retOrder => {
        //         return !!retOrder;
        //     })
        // );
    }

    private getOrCreateOrder(orderId, orderType, canCreateOrder): Observable<Order> {
        if (orderId) {
            return this.orderService.getOrder(orderId);
        }

        if (canCreateOrder) {
            return this.orderService.createOrder(orderType).pipe(
                switchMap( retOrder => {
                    if (!retOrder) {
                        return of(retOrder);
                    }
                    return this.checkPaymentDefined(retOrder, true);
                })
            );
        }

        return of(null);
    }

    public shopProduct(order: Order, product: Product,
                       canCreateOrder = false,
                       showOrderSelector = false) {
        if (!order || !product) {
            return;
        }

        this.productService.getProduct(product.id, order.entity_id, false, true).pipe(
            switchMap( retProduct => {
                if (!retProduct) {
                    return of({shopOrder: null, shopProduct: null});
                }
                return this.getOrCreateOrder(order.id,  order.type, canCreateOrder).pipe( map( retOrder => {
                    return {shopOrder: retOrder, shopProduct: retProduct};
                }));
            }),
            switchMap(({shopOrder, shopProduct}) => {
                order = shopOrder;
                if (!shopOrder || !shopOrder?.id || !shopProduct) {
                    return of ({shopOrder: null, shopProduct: null, hasAccess: false});
                }
                return this.hasAccessToProduct(order, shopProduct).pipe( map( result => ({shopOrder, shopProduct, hasAccess: result})));
            }),
            switchMap(({shopOrder, shopProduct, hasAccess}) => {
                if (hasAccess && shopProduct && shopOrder) {
                    this.productService.populateProduct(shopProduct);
                    const orderItem = this.getOrderItemFor(shopOrder, null, shopProduct);
                    const retOrderItem = this.getOrderItemFor(shopOrder, orderItem, shopProduct);
                    return from(this.editOrderItemModal(order, retOrderItem, showOrderSelector))
                } else {
                    return of(null);
                }
            })
        ).subscribe(() => {});
    }


    public showProductInModal(product: Product, order?: Order, showOrderSelector = false): Observable<any> {
         if (!product) {
             return of (null);
         }

         let showingProduct: Product = product;
         return this.getActualProduct(product).pipe(
             tap( retProduct => showingProduct = retProduct),
             switchMap( () => this.validateProduct(showingProduct)),
             switchMap( valid => {

                 let clonedOrderItem = this.getClonedOrderItem(showingProduct, order, valid);
                 if (!valid) {
                     // if product cannot be purchasable - show as readonly modal
                    return this.showModalWindow(clonedOrderItem, order, true);
                 }

                 return this.getActiveOrder(showingProduct).pipe(
                     switchMap( retOrder => {
                         if (retOrder && retOrder.containsProduct(showingProduct)) {
                             clonedOrderItem = this.getClonedOrderItem(showingProduct, retOrder, true);
                         }
                         return this.showModalWindow(clonedOrderItem, retOrder, false, showOrderSelector);
                     })
                 );
             }),
         );

    }

    public prefetchCapexForEntity(orderItem: OrderItem) {
        if (!orderItem || !orderItem.product) {
            return;
        }

        // prefetch only for employees
        if (!this.authService.isEmployee) {
            return;
        }

        const {product} = orderItem;
        if (product.hasLease) {
            const entityIds: number[] =
                orderItem.deliveries.filter( delivery =>
                    (delivery.data.entity_id > 0 && delivery.data.entity_id !== this.authService.user.entity_id))
                    .map( delivery => delivery.data.entity_id);

           if (isEmptyArray(entityIds)) {
               return;
           }
            forkJoin( entityIds.map( id => this.entityService.prefetchCapex(id))).subscribe( data => {
            })
        }
    }

    public checkLeaseStatus(): Observable<string> {
        return this.entityService.reloadCapex(this.authService.user.entity_id);
    }

    public shouldShowLeaseWarning(product: Product) {
        if (!product.hasLease) {
            return false;
        }

        if (this.authService.isEmployee) {
            return false;
        }

        return product.attr_capex_type !== this.authService.user.entity.capex;
    }


    public isProductRecommended(product: Product, order: Order = null): boolean {
        if (!product) {
            return null;
        }
        if (this.authService.isWholeSaler) {
            return product.is_recommended;
        }

        if (this.authService.isEmployee) {
            return product.is_recommended && (order?.isWSLR || order?.isDecisionPoint)
        }

    }

    public canShowRecommendsProducts(order: Order): boolean {
        if (!order) {
            return false;
        }
        if (order.isByingWindow) {
            if (this.authService.isWholeSaler) {
                return true;
            }

            if (this.authService.isEmployee) {
                return order.isWSLR || order.isDecisionPoint;
            }
        }

        return false;
    }

    public showRecommendedProductExportResult(exportType: 'pdf' | 'xlsx') {
        this.modalService.showRecommendedProductExportResult(exportType);
    }


    private getClonedOrderItem(product: Product, order: Order, withReset = false): OrderItem {
        const originalOrderItem = order.getItemForProduct(product, {});
        const clonedOrderItem = new OrderItem(originalOrderItem);
        if (withReset) {
            clonedOrderItem.resetFinancialTotals();
            delete clonedOrderItem.id;
            clonedOrderItem.deliveries.forEach( d => {
                delete d.id;
            });
            clonedOrderItem.checkForAvailableItems();
        }

        return clonedOrderItem
    }

    // check if there is a product replacement
    private getActualProduct(product: Product): Observable<Product> {
        if (!product.replacedProductId) {
            return of(product);
        }

        return this.productService.getProduct(product.replacedProductId);
    }

    // validate product with order window. For OD order window is 0
    private validateProduct(product: Product): Observable<boolean> {
        if (!product) {
            return of(null);
        }

        // check if product canbe reordered
        if (!product.productActive) {
            return of(false);
        }

        let orderWindow: OrderWindow = null;
        if (product.isBuyingWindow) {
            if (!this.orderService.activeOrderWindow) {
                return of(null);
            }
            orderWindow = this.orderService.activeOrderWindow;
        }

        // check if product is still can buy
        return this.productService.validateProduct(product, orderWindow);
    }


    private getOrderByType(type: string): Observable<Order> {
        const order = type === ECOMMERCE_TYPE ? this.orderService.activeODOrder : this.orderService.activeBWOrder;
        if (order) {
            return of(order)
        }
        return this.orderService.createOrder(type);
    }

    private getActiveOrder(product: Product): Observable<Order> {
        if (!product) {
            return of(null);
        }
        const orderType = product.isOnDemand ? ECOMMERCE_TYPE : PREORDER_TYPE;
        return this.getOrderByType(orderType).pipe(
            switchMap( retOrder => {
                if (!this.authService.canEditOrder(retOrder)) {
                    return of(null);
                }
                return this.checkPaymentDefined(retOrder, true);
            }
        ));
    }

    private showModalWindow(orderItem: OrderItem, order: Order, readonly = false, showOrderSelector = false) {
        if (!orderItem || !order) {
            return of(null);
        }

        if (readonly) {
            return from(this.modalService.showReadonlyProductModal(order, orderItem))
        }

        return from(this.editOrderItemModal(order, orderItem, showOrderSelector));
    }

    private getProductPurchasedDetails(productId: number) {
        this.recommendedService.getProductOrdersIfo(productId);
    }
}
